import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useRef, useState } from "react";

import Header from "./Header";
import User from "../models/User";
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"

import banner1 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-01.png"
import banner2 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-02.png"
import banner3 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-03.png"
import banner4 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-04.png"
import banner6 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-06.png"
import banner7 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-07.png"
import banner8 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-08.png"
import banner9 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-09.png"
import banner10 from "../assets/v9/20230505_WellWeb_Home x Dhanar X Komune Last 150-10.png"

import 'react-ig-feed/dist/index.css'

import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import ReactPlayer from "react-player";

let requestCountDownTime = 0

export default function LandingPageV9(props) {

  const homeRef = useRef(null)
  const problemRef = useRef(null)
  const solutionRef = useRef(null)
  const productRef = useRef(null)
  const contactUsRef = useRef(null)

  const [activeHeader, setActiveHeader] = useState('home')
  const containerRef = useRef(null);

  const history = useHistory();
  const userModel = new User();

  const cardRef = useRef(null);

  const iFeedRef = useRef(null)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMobile = windowWidth < 768

  return <div ref={containerRef}>
    <Header
      clearMode={false}
      activeHeader={"home"}
      loginButton={true}
      onHeaderChosen={(headerChosen) => {
        if(headerChosen === "HOME") window.scrollTo(0, homeRef?.current?.offsetTop - 80)
        if(headerChosen === "PROBLEM") window.scrollTo(0, problemRef?.current?.offsetTop - 80)
        if(headerChosen === "SOLUTION") window.scrollTo(0, solutionRef?.current?.offsetTop - 80)
        if(headerChosen === "PRODUCT") window.scrollTo(0, productRef?.current?.offsetTop - 80)
        if(headerChosen === "CONTACT US") window.scrollTo(0, contactUsRef?.current?.offsetTop - 80)
      }}
    />

    <div
      style={{
        marginTop: 0
      }}
    >
      <div ref={homeRef}></div>
      <Element name="home" className="element">
        <Row style={{ marginTop: -5, overflow: "hidden" }}>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner1} />
          </Col>
        </Row>
      </Element>
      <div ref={problemRef}></div>
      <Element name="problem" className="element">
        <Row>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner2} />
          </Col>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner3} />
          </Col>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner4} />
          </Col>
        </Row>
      </Element>
      <div ref={solutionRef}></div>
      <Element name="solution" className="element">
        <Row>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner6} />
          </Col>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner7} />
          </Col>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner8} />
          </Col>
        </Row>
      </Element>
      <div ref={productRef}></div>
      <Element name="product" className="element">
        <Row>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner9} />
          </Col>
          <Col md={12}>
            <img
              style={{
                width: "100%",
                objectFit: "contain"
              }}
              src={banner10} />
          </Col>
        </Row>
      </Element>
      <div ref={contactUsRef}></div>
      <Element name="contact-us" className="element">
        <Row style={{ background: "#47bcc6" }}>
          <Col md={12} style={{ textAlign: "center", color: "white", display: "flex", flexDirection: "column" }}>
            <div style={{ color: "#004283", fontFamily: "syne", fontWeight: 800, paddingTop: 20, fontSize: 24 }}>Contact Us</div>
            <div style={{ fontFamily: "syne", flex: 1, marginBottom: 50 }}>wellworld.id@gmail.com</div>
            <div style={{ fontFamily: "syne" }}>Copyright © 2024. PT. Yayasan Warisan Ibu Pertiwi</div>
          </Col>
        </Row>
        {/*<Row style={{marginTop : -7, overflow : "hidden"}}>*/}
        {/*  <Col md={12}>*/}
        {/*    <ReactPlayer*/}
        {/*      muted={true}*/}
        {/*      playing={true}*/}
        {/*      url={videoHome}*/}
        {/*      width={"100%"}*/}
        {/*      height={"100%"}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Element>

    </div>

    {/*<Footer/>*/}

  </div>
}
